import axios from 'axios';
import { API_ICLOUD, API_ICLOUD_KEY } from '../../env';

export const icloud = async (data) => {
  try {
    return await axios.post(`${API_ICLOUD}/send-device-action`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
        'x-api-key': API_ICLOUD_KEY,
      },
    });
  } catch (error) {
    return error;
  }
};

export const actionLog = async (contract) => {
  try {
    return await axios.get(`${API_ICLOUD}/get-list-history`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
        'x-api-key': API_ICLOUD_KEY,
      },
      params: {
        contract_no: contract,
      },
    });
  } catch (error) {
    return error;
  }
};
