import axios from 'axios';
import { API_MDM } from '../../env';
export const actionLockStatusLog = async (serialNumber, page, limit) => {
  try {
    const mdmUrl = `${API_MDM}/mdm/get_process`;
    const query = {
      params: {
        serial_number: serialNumber,
        page: page,
        limit: limit,
      },
      headers:
      {
        Authorization: `${localStorage.getItem('token')} `

      }
    };
    const { data } = await axios.get(mdmUrl, query);
    return data

  } catch (error) {
    return error;
  }
};