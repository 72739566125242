import { useEffect, useState } from 'react';
import { Button, Modal, Typography, Tabs, Empty } from 'antd';
import ActionLogTable from '../../table/ActionLog';
import LogStatusTable from '../../table/ActionLogStatusTable';
import { actionLog } from '../../../redux/api/icloud';
import { actionLockStatusLog } from '../../../redux/api/mdm';

export default function ActionLog({ visible, onCancel, contract_no, serialNumber }) {
  const { Title } = Typography;
  const [logs, setLogs] = useState([]);
  const [mdmLogs, setMdmLogs] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10, total: 0, totalPages: 0 });
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const fetchData = async (page = 1, limit = 10, setLoadingState = false) => {
    try {
      if (setLoadingState) {
        setLoading(true);
      }

      const fetchLogs = [actionLog(contract_no)];
      if (serialNumber) {
        fetchLogs.push(actionLockStatusLog(serialNumber, page, limit));
      }

      const [iCloudLogs, mdmDataLog] = await Promise.all(fetchLogs);
      const iCloudData = iCloudLogs?.data?.result || [];
      const mdmData = mdmDataLog?.result || [];
      const pageData = mdmDataLog?.pagination || {};

      setLogs(iCloudData);
      setMdmLogs(mdmData);
      setPagination(pageData);

      if (iCloudData.length > 0) {
        setActiveTab('icloud');
      } else if (mdmData.length > 0) {
        setActiveTab('mdm');
      } else {
        setActiveTab(null);
      }
    } catch (err) {
      Modal.error({ title: 'Error', content: 'Failed to fetch logs' });
    } finally {
      if (setLoadingState) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (visible) {
      fetchData(pagination.page, pagination.limit);
    }
  }, [visible, pagination.page, pagination.limit]);
  console.log("object", logs.length);
  console.log("check", mdmLogs.length);
  return (
    <Modal open={visible} onCancel={onCancel} width={900} closable={false} footer={null}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
        <Title level={5}>Action Log</Title>
        <Button type="primary" onClick={() => fetchData(pagination.page, pagination.limit, true)} loading={loading}>
          Refresh
        </Button>
      </div>

      {(logs.length === 0 && mdmLogs.length === 0) ? (
        <Empty description="ไม่พบข้อมูล" />
      ) : (
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          {logs.length > 0 && (
            <Tabs.TabPane tab="iCloud" key="icloud">
              <ActionLogTable logs={logs} pagination={pagination} />
            </Tabs.TabPane>
          )}
          {mdmLogs.length > 0 && (
            <Tabs.TabPane tab="MDM" key="mdm">
              <LogStatusTable statusLogs={mdmLogs} pagination={pagination} onPageChange={fetchData} />
            </Tabs.TabPane>
          )}
        </Tabs>
      )}
    </Modal>
  );
}
